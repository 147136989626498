<template>

  <!-- Preline sidebar menu for Phone screens -->
  <phoneMenu v-if="this.userDetails" :user-details="this.userDetails"></phoneMenu>

  <div class="container-div h-screen overflow-hidden md:overflow- print:h-max">
    <!-- <div>
		<button v-if="$auth.isLoggedIn" @click="$auth.logout()">Logout</button>
		
	</div> -->
    <div class="wrapper print:h-max" :class="['transition-all duration-400 linear',
      navbarHidden ? 'transform -translate-y-[38px]' : '']">
      <!-- Navbar -->

      <Navbar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted" :user-details="this.userDetails"
        @toggle-navbar="toggleNavbar" @show-modal="showGuestNavigationErrorModal = true"
        class=" h-[6%] md:h-[8.5%] md:sticky top-0 w-full z-10" />


      <div class="h-[100%] md:h-auto flex print:h-max" style="height: 100%;">

        <!-- Sidebar -->
        <Sidebar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted" :user-details="this.userDetails"
          @show-modal="showGuestNavigationErrorModal = true"
          class="hidden md:flex h-[7%] md:sticky md:top-[8.5%] print:hidden" />

        <!-- Phone bottom navigation bar -->
        <phoneBottomNavbar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted"
          :user-details="this.userDetails" @show-modal="showGuestNavigationErrorModal = true"
          class="md:hidden h-[7%] md:sticky md:top-[8.5%] print:hidden" :class="['transition-all duration-400 linear',
            navbarHidden ? 'transform -translate-y-[-80px] z-40' : '']"></phoneBottomNavbar>

        <main class="flex-grow print:h-max" :class="[{ 'relative h-[87%] md:h-[91.3%] overflow-hidden md:w-[80vw] md:overflow-visible': $auth.isLoggedIn && termsAndPrivacyAccepted },
        navbarHidden ? 'h-[97.5%]' : 'transition-all duration-400 linear']">


          <!-- Upload status -->
          <UploadProgress
            v-if="uploadDetails.visible && uploadDetails.progress && uploadDetails.totalImagesToBeUploaded"
            :upload-progress="uploadDetails.progress"
            :total-images-to-be-uploaded="uploadDetails.totalImagesToBeUploaded" />
          <UploadStatus v-if="uploadDetails.visible && uploadDetails.details" :details="uploadDetails.details" />
          <ServerErrorToast v-if="uploadDetails.visible && uploadDetails.details && uploadDetails.details.error"
            :details="uploadDetails.details"></ServerErrorToast>

          <router-view :options-response="optionsResponse" :toggleUploadStatus="toggleUploadStatus"
            :user-details="this.userDetails"></router-view>
        </main>
      </div>
    </div>

    <template v-if="showGuestNavigationErrorModal">
      <navigationErrorModal @close="showGuestNavigationErrorModal = false"></navigationErrorModal>
    </template>

  </div>
</template>


<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';
import UploadStatus from './components/photoEssays/UploadStatus.vue';
import UploadProgress from './components/photoEssays/UploadProgress.vue';
import ServerErrorToast from './components/photoEssays/ServerErrorToast.vue'
import navigationErrorModal from './components/guestFacilitator/navigationErrorModal.vue';
import phoneMenu from './components/navigationMenu/phoneMenu.vue';
import phoneBottomNavbar from './components/navigationMenu/phoneBottomNavbar.vue';

import 'preline/preline';
import { onMounted, computed } from 'vue';




export default {
  components: {
    Navbar,
    Sidebar,
    UploadStatus,
    UploadProgress,
    ServerErrorToast,
    navigationErrorModal,
    phoneBottomNavbar,
    phoneMenu,
  },
  inject: {
    $call: '$call', // Injecting the $call property
    $auth: '$auth', // Injecting the $auth property
  },
  provide() {
    return {
      userDetails: computed(() => this.userDetails)
    }
  },

  data() {
    return {
      optionsResponse: null, // initially null
      userDetails: null,
      showUploadStatus: false,
      uploadDetails: {
        visible: false,
        details: {
          data: null,  // Ensure there is a nested structure for data
          error: null  // and error to avoid undefined issues
        },
        progress: 0,  // It might be better to start with 0
        totalImagesToBeUploaded: 0,  // and 0 here if it makes sense for your logic
        termsAndPrivacyAccepted: false, // To only render the routes when user has accepted terms of dervices and privacy policy 
      },
      showGuestNavigationErrorModal: false,
      navbarHidden: false,

    };
  },
  setup() {
    onMounted(() => {
      // console.log("preline mounted and initiated in App.vue");
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
      }, 100)
    });
  },
  methods: {
    async fetchFormOptionsData() {
      try {
        console.log("Fetch options data called in App.vue")
        // call to the 'fetch_data' function on the server side
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.fetch_options_data');
        console.log("Fetch options data Response", response)
        this.optionsResponse = response.data; // store full response object

      } catch (error) {
        console.error('Error fetching options data:', error);
      }
    },
    async fetchUserDetails() {
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.common.user_details.api.get_user_details')
        if (response.status === 'success') {
          this.userDetails = response.user_details
        }
        console.log("User Details fetched in App.vue", response)
      } catch (error) {
        console.error('Error fetching User details:', error)
      }
    },
    async fetchUserAgreementAcceptance() {
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.check_user_agreement_status_from_app')

        if (response.status === 'success') {
          this.termsAndPrivacyAccepted = response.terms_and_privacy_accepted
        }
        console.log("This is the User Agreement status response in App.vue", response)
      } catch (error) {
        console.error('Error fetching User Agreement details:', error)
      }

    },

    toggleUploadStatus(status) {

      console.log("This is the upload status recieved in app", status)
      this.uploadDetails.details = status.details;
      this.uploadDetails.visible = status.visible;
      this.uploadDetails.progress = status.progress;
      this.uploadDetails.totalImagesToBeUploaded = status.totalFiles

      console.log("This is the value of uploadDetails after assigning", this.uploadDetails)
    },
    showNavigationMenu() {
      console.log("Show Menu recieved in APP")
    },
    toggleNavbar() {
      // this.navbarHidden = !this.navbarHidden;
    }
  },
  mounted() {
    if (this.$auth.isLoggedIn) {
      this.fetchFormOptionsData();
      this.fetchUserDetails();
      this.fetchUserAgreementAcceptance();
    }
  },
  watch: {
    '$auth.isLoggedIn'(newVal) {
      if (newVal) {
        this.fetchFormOptionsData();
        console.log("Loging state changed, fetFormOptionData called inside Watcher")
        this.fetchUserDetails();
      }
    }
  },

};
</script>

<style>
html,
body,
#app,
.container-div,
.wrapper {
  height: 100%;
}
</style>