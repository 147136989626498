<template>
  <div :class="{ 'md:bg-[#FDD835]': isActive && !disabled, 'md:bg-[]': !isActive && !this.isMenuOpen && !disabled , '[--trigger:hover] [--placement:right]': !this.isMenuOpen}, this.isMenuOpen ? 'md:w-full md:p-[18px_10px_12px_10px]': 'md:w-[78.4px] lg:w-[65px]'" class="h-auto md:h-[52px] p-2 my-1 md:my-0 rounded-[8px] cursor-pointer hs-tooltip inline-block" @click="handleClick">
   
    <div class="flex items-center h-full" :class="{ 'pointer-events-none': disabled }, this.isMenuOpen ? 'flex-row w-full pl-2 pb-1': 'flex-col justify-center gap-1'">  
      <img :src="currentIcon" alt="" class="h-8 w-8 lg:h-7 lg:w-7">
     
      <span :class="this.isMenuOpen ? 'ml-5 md:block': 'hidden'" class="text-xs font-semibold">
        {{ label }}
      </span>

      <!-- menu hover label -->
      
      <span v-if="!this.isMenuOpen" class="hidden md:flex hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1.5 px-4 bg-transparent md:bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip" :class="[isActive ? 'left-24':'left-20']">
          <span class="hidden md:flex">{{ label }}</span>
      </span>  
    </div>
     
  </div>
</template>


<script>
export default {
  props: {
    label: String,
    path: String,
    iconSelected: String,
    iconUnselected: String,
    isMenuOpen: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    alternativeClick: {
      type: Function,
      default: null
    }
  },
  computed: {
    isActive() {
      return this.$route.path === this.path;
    },
    currentIcon() {
      return this.isActive && !this.disabled ? this.iconSelected : this.iconUnselected;
    }
  },
  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.preventDefault();
        if (this.alternativeClick) {
          this.alternativeClick();
        }
      } else {
        this.$router.push(this.path);
      }
    }
  }
};
</script>






