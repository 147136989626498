<template>
  <div class="hs-dropdown relative inline-flex">
    <button id="hs-dropdown-custom-trigger" type="button" class="hs-dropdown-toggle py-1 ps-1 pe-3 inline-flex 
      items-center gap-x-0.5 text-sm font-semibold rounded-md border border-gray-200 bg-customNavbar 
      text-gray-800 shadow-sm hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none 
      dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none 
      dark:focus:ring-1 dark:focus:ring-gray-600">
      <!-- <img class="w-8 h-auto rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Maria"> -->
      <div class="size-6 md:size-8 rounded flex justify-center items-center text-xl border-1.5 border-black bg-[#fef3c2]">
        <!-- P -->
         {{ userDetails.first_name[0] }}
      </div>
      <!-- <span class="text-gray-600 font-medium truncate max-w-[7.5rem] dark:text-gray-400">Maria</span> -->
      <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
    </button>

    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 
      opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border 
      dark:border-gray-700" aria-labelledby="hs-dropdown-custom-trigger">
      <router-link v-if="userDetails && userDetails.is_system_manager" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
      to="/front-portal/user-management">
        User management
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager && userDetails.first_name === 'Sandeep'" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/admin-controls">
        Admin Controls
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/summary">
        Summary Generation
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/observation-link">
        Link Observations
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/summary-individual">
        Individual Observations
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/calendar/plan-week">
        Plan Week(Test)
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager"
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/sandbox">
        Prompt Sandbox
      </router-link>
      <a v-if="userDetails && userDetails.is_system_manager && (userDetails.first_name.toLowerCase() === 'sai' 
        || userDetails.first_name.toLowerCase() === 'sandeep' || userDetails.first_name.toLowerCase() === 'priyanka' )" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" href="https://testpod.comini.in/student-reports/">
        Student Reports
      </a>
      <!-- <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
        Purchases
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
        Downloads
      </a> -->
        <!-- class="py-1 px-3 text-sm md:py-1.5 md:px-1 md:text-base ml-2 md:ml-1 hover:bg-customNavbar border-black" > -->
      <button 
        v-if="auth.isLoggedIn" 
        @click="auth.logout()"
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700 w-full">
        logout
      </button>
    </div>
  </div>
</template>

<script setup>
import { inject, watch } from 'vue';


const auth = inject('$auth')
const userDetails = inject('userDetails')

console.log(userDetails)

watch(userDetails, newDetails => {
  console.log(newDetails)
})
</script>