<template>
  <div class="hs-dropdown relative inline-flex [--auto-close:inside]">
    <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle  text-sm font-medium rounded-lg 
      bg-[#FDD835] hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] disabled:opacity-50 
      disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white 
      dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 aspect-square size-7 md:size-8 flex justify-center 
      items-center cursor-pointer pl-2 md:pl-0" 
      aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown" @click="newNotificationsPresent = false">
      <NotificationIcon :notifications="unreadCount" :newNotificationsPresent="newNotificationsPresent"
        :animateIcon="animateNotifcationIndicator" />
    </button>

    <NotificationDropDown :notifications="notificationList" :unreadCount="unreadCount"
      :realtimList="rtNotificationQueue" :markAsRead="markAsRead" />
    <ToastContainer :notificationQueue="rtNotificationQueue" :addToQueueFn="addToQueue" 
      :makeInactive="makeInactive" />
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import NotificationIcon from '../icons/NotificationIcon.vue';
import { computed, ref } from 'vue';
import ToastContainer from '@/components/notifications/ToastContainer.vue';
import NotificationDropDown from '@/components/notifications/NotificationDropdown.vue';
import { io } from "socket.io-client"
import config from '../../../../../../sites/common_site_config.json'

const host = window.location.hostname
const port = window.location.port ? `${config.socketio_port}` : '';
const protocol = port ? 'http' : 'https'
const siteName = window.site_name || host
const url = `${protocol}://${host}:${port}/${siteName}`

// const socket = io("http://localhost:9001/zippie.local", {
const socket = io(url, {
  withCredentials: true,
  reconnectionAttempts: 5,
})

const notificationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.curriculum_notification.api.get_user_notifications',
 auto: true
})

const markAsReadResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.curriculum_notification.api.mark_as_read',
})

console.log("Notifications", notificationResource)

const readNotifications = ref([])

const rtNotificationQueue = ref([])
const newNotificationsPresent = ref(true)
const animateNotifcationIndicator = ref(false)

const notificationList = computed(() => {
  if (notificationResource.data?.status !== 'success')
    return [];

  const notifications = [...rtNotificationQueue.value,...notificationResource.data.notifications];
  readNotifications.value.forEach((notiName) => {
    const notiIndex = notifications.findIndex((noti) => noti.name === notiName);
    if (notiIndex === -1) return;
    notifications[notiIndex].status = 'Read'
  })
  // return notificationResource.data.notifications;
  return notifications;
})

const unreadCount = computed(() => {
  return notificationList.value.filter((notification) => notification.status === 'Unread').length
})

const addToQueue = (sampleNoti) => {
  // console.log("Adding new notification")
  // console.log('notificationQue', rtNotificationQueue.value)
  const newIndex = rtNotificationQueue.value.length;
  // console.log("new index", newIndex)
  rtNotificationQueue.value.push({
    ...sampleNoti,
    actor_name: `${sampleNoti.actor_name}-${newIndex}`,
    active: true
  })

  setTimeout(() => {
    rtNotificationQueue.value[newIndex].active = false;
  }, 5000)
}

const makeInactive = (queueIndex) => {
  rtNotificationQueue.value[queueIndex].active = false;
}

const notificationSocketEventHandler = (message) => {
  if (!message.notification) return
  // console.log("Notification received", message.notification)
  // console.table(message.notification)

  const newIndex = rtNotificationQueue.value.length;
  rtNotificationQueue.value.push({
    ...message.notification,
    active: true
  })
  newNotificationsPresent.value = true;
  animateNotifcationIndicator.value = true;
  setTimeout(() => animateNotifcationIndicator.value = false, 8000)

  setTimeout(() => {
    rtNotificationQueue.value[newIndex].active = false;
  }, 5000)
}

socket.on(`notification`, notificationSocketEventHandler);

const markAsRead = async (notificationId) => {
  readNotifications.value.push(notificationId)
  try {
    await markAsReadResource.submit({
      notification_id: notificationId,
    })

    if (markAsReadResource.data?.status !== 'success') {
      readNotifications.value = readNotifications.value.filter((noti) => noti.name !== notificationId)
      console.error("Failed to mark notification as read!")
      console.error(markAsReadResource.data?.message)
    // } else {
    //   console.log("Successfully Marked as read")
    }
  } catch (error) {
    readNotifications.value = readNotifications.value.filter((noti) => noti.name !== notificationId)
    console.error("Failed to make mark as read request!")
    console.error(error)
  }
}
</script>