<template>
    <div class="fixed bottom-[80vh] md:bottom-[0vh] md:right-0 md:mb-4 md:mr-6 md:w-[328px] w-full" style="z-index: 200;">
     
      <div v-if="uploadProgress" class="mx-12 md:mx-0">
        <!-- Upload Progress -->
        <!-- Toast -->
        <div id="dismiss-toast" class="max-w-xs relative bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-neutral-800 dark:border-neutral-700" role="alert">
          <div class="flex p-4">
            <div class="flex-shrink-0">
              <!-- Icon -->
              <span class="m-1 inline-flex justify-center items-center size-8 rounded-full bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:text-neutral-200">
                <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242"></path>
                  <path d="M12 12v9"></path>
                  <path d="m16 16-4-4-4 4"></path>
                </svg>
              </span>
              <!-- End Icon -->

              <button type="button" class="absolute top-3 end-3 inline-flex flex-shrink-0 justify-center items-center size-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white" data-hs-remove-element="#dismiss-toast">
                <span class="sr-only">Close</span>
                <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>

            <div class="grow ms-3 me-5">
              <!-- Dynamic message based on upload progress -->
              <template v-if="uploadProgress < 100">
                <h3 class="text-gray-800 font-medium text-sm dark:text-white">
                  Uploading {{ totalImagesToBeUploaded }} files
                </h3>
              </template>
              <template v-else>
                <h3 class="text-gray-800 font-medium text-sm dark:text-white">
                   Files transfer complete; server is processing them
                </h3>   
              </template>

              <!-- Progress -->
              <div class="mt-2 flex flex-col gap-x-3">
                <span class="block mb-1.5 text-xs text-gray-500 dark:text-neutral-400">{{uploadProgress}}%</span>
                <div class="flex w-full h-1 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" aria-valuenow="{{uploadProgress}}" aria-valuemin="0" aria-valuemax="100">
                  <div class="flex flex-col justify-center overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap dark:bg-neutral-200" :style="{ width: uploadProgress + '%' }"></div>
                </div>
              </div>
              <!-- End Progress -->
            </div>
          </div>
        </div>
        <!-- End Toast -->

      </div> 
         
    </div>
</template>





<script>

export default {
    data() {
      return {
        toastVisibility: {},
      };
    },
    mounted(){
      setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside New-Events form after 100ms delay");
          }, 100)

      console.log("this is number of toatl images in toast component===============",this.totalImagesToBeUploaded)  
    },
    props: {
      details: {
      type: Object,
    },
    uploadProgress: {
      type: Object,
    },
    totalImagesToBeUploaded: {
    type: Number,
  },
  },
  
   
  }
  </script>
  
  <style>
 
  </style>
  