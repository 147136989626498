<template>

    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
       <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] max-w-[400px] overflow-auto">
         
         <!-- Cross button
         <div class="sticky top-2 mr-2 flex justify-end z-10">
             <button @click="closeComponent" class="border-black">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
             </button>
         </div> -->
 
         <!-- Content container -->
         <div class="w-full flex flex-col items-center"> 
            <div class="font-semibold text-2xl text-center mt-9 mb-4"> Oops!</div>

            <div class="relative text-sm text-center text-[#6B7280] w-[85%]">
                Hi, you currently do not have access to these parts of Zippie. We'll be rolling out our parent login soon and you'll be able to access the rest of the features!
                <!-- Finger crossed emoji in span and svg -->
                <span style="font-size:23px;">&#129310;</span>
            </div> 
    
            <!-- Submit Button -->   
            <div class="flex mb-9 mt-8 justify-center">
                <button @click="closeComponent" class="bg-black text-white py-2 md:py-2 px-2 w-[170px] text-black rounded-lg cursor-pointer hover:bg-gray-800 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">Dismiss</button>
            </div>
        </div>    
         
      </div>
     </div>    
 </template>



<script setup>

import { defineEmits } from 'vue';

const props = defineProps({
      worksheetName: String,
    });



const emit = defineEmits(['close']);


const closeComponent = () => {
        emit('close');
    };  

</script>    