 <template>
 
 <div class="fixed bottom-[0vh] right-0 mr-1 mb-1" style="z-index: 200;">

    <!-- Server error or server is down-->
    <div v-if="details && details.type ==='error'">         
        <!-- Check the number of successful uploads -->
        <!-- Toast -->
        <div id="dismiss-toast"  class="hs-removing:translate-x-5 hs-removing:opacity-0 transition duration-300 max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-neutral-800 dark:border-neutral-700 mb-1 mr-2" role="alert">
        <div class="flex p-4">
          <svg class="flex-shrink-0 size-4 text-red-500 mt-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
          </svg>
            <p class="text-sm text-gray-700 dark:text-neutral-400">
            {{ details.error}}: failed to connect to server.
            </p>

            <div class="ms-auto">
            <button type="button" class="inline-flex flex-shrink-0 justify-center items-center size-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white" data-hs-remove-element="#dismiss-toast">
                <span class="sr-only">Close</span>
                <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
                </svg>
            </button>
            </div>
        </div>
        </div>
    <!-- End Toast -->
    </div> 

  </div>  

</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  mounted(){
    console.log("Server error component mounted")
    setTimeout(() => {
          window.HSStaticMethods.autoInit(); //Preline is initialised
        console.log("Preline Initialise inside server Error toast after 100ms delay");
        }, 100) 
  },
  props: {
    details: {
    type: Object,
  },
 
},

  methods: {
    
  },
 
}
</script>