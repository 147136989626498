<template>
  <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 
    hidden min-w-60 bg-white shadow-md rounded-lg p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:border 
    dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 
    after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full xl:min-w-80 
    flex flex-col gap-4 lg:w-96 lg:max-w-96 z-50 max-h-[86vh] md:max-h-[70vh] xl:max-h-[50vh] 
    h-[86vh] md:h-[70vh] xl:h-[50vh]
    overflow-y-auto" 
    id="notificationDropdown"
    role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
    <div class="flex justify-between items-center">
      <h3>Notifications</h3>
      <p class="text-xs font-medium text-gray-700 flex items-center justify-center gap-1 hidden">
        Mark all as read
        <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.125 6.1875L4.25 9.3125M8.625 4.3125L11.75 1.1875M6.125 6.1875L9.25 9.3125L16.75 1.1875" stroke="black" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </p>
    </div>

    <div class="flex gap-4">
      <div :class="cn(displayFilter === 'Unread' && 'border-2 rounded-xl border-blue-600')">
        <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold
          rounded-lg border border-gray-200 text-gray-700 hover:border-blue-600 hover:text-blue-600
          disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400
          dark:hover:text-blue-500 dark:hover:border-blue-600 bg-white`,
          displayFilter === 'Unread' && 'border border-blue-600')" @click="() => changeDisplayFilter('Unread')">
          Unread
          <span class="bg-[#EF4444] text-white rounded-full h-6 w-6 inline-flex justify-center
            items-center text-xs">
            {{ unreadCount }}
          </span>
        </button>
      </div>
      <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
        rounded-lg border border-gray-200 text-gray-700 hover:border-blue-600 hover:text-blue-600 
        disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 
        dark:hover:text-blue-500 dark:hover:border-blue-600 bg-white`, displayFilter === 'All' && 'border-blue-600')"
        @click="() => changeDisplayFilter('All')">
        All
      </button>
    </div>

    <div v-if="todaysNotifications.length" class="flex flex-col gap-2">
      <h4 class="font-medium text-sm mb-2">
        Today
      </h4>
      <NotificationItem v-for="notification in todaysNotifications" :notification="notification"
        :key="notification.name"
        @click="() => markNotificationAsRead(notification)" />
    </div>

    <div v-if="yesterdaysNotifications.length" class="flex flex-col gap-2">
      <h4 class="font-medium text-sm">
        Yesterday
      </h4>
      <NotificationItem v-for="notification in yesterdaysNotifications" :notification="notification"
        :key="notification.name"
        @click="() => markNotificationAsRead(notification)" />
    </div>

    <div v-if="earlierNotifications.length" class="flex flex-col gap-2">
      <h4 class="font-medium text-sm">
        Earlier
      </h4>
      <!-- <div v-for="notification in earlierNotifications" :class="cn(`border border-gray-200 p-3 rounded-md`,
        notification.status === 'Unread' && 'bg-[#FDF9EC]'
      )">
        <p class="text-xs font-medium">
          {{ notification.message }}
        </p>
      </div> -->
      <NotificationItem v-for="notification in earlierNotifications" :notification="notification"
        :key="notification.name"
        @click="() => markNotificationAsRead(notification)" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { cn } from '@/components/ui/utils.js';
import { isToday, isYesterday } from '@/lib/utils.js';
import NotificationItem from '@/components/notifications/NotificationItem.vue';
// import { HSDropdown } from 'preline/preline'

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const props = defineProps([
  'notifications', 'unreadCount', 'realtimeList',
  'markAsRead'
])

console.log("PROPS NOT", props.notifications)

// can be "All" or "Unread"
const displayFilter = ref('All')

const changeDisplayFilter = (newMode) => {
  displayFilter.value = newMode
}

const todaysNotifications = computed(() => {
  if (!props.notifications)
    return []
  return props.notifications.filter((notification) => {
    const isUnread = notification.status === 'Unread';
    const showNotification = displayFilter.value === 'Unread' ? isUnread : true;
    return isToday(notification.creation_time) && showNotification;
  }).toSorted((a, b) => {
    return new Date(b.creation_time) - new Date(a.creation_time);
  });
})

const yesterdaysNotifications = computed(() => {
  if (!props.notifications)
    return []
  return props.notifications.filter((notification) => {
    const isUnread = notification.status === 'Unread';
    const showNotification = displayFilter.value === 'Unread' ? isUnread : true;
    // console.log({ notification: notification, yest: isYesterday(notification.creation_time)})
    return isYesterday(notification.creation_time) && showNotification;
  })
})

const earlierNotifications = computed(() => {
  if (!props.notifications)
    return []
  return props.notifications.filter((notification) => {
    const isUnread = notification.status === 'Unread';
    const showNotification = displayFilter.value === 'Unread' ? isUnread : true;
    return !isYesterday(notification.creation_time) && !isToday(notification.creation_time) && showNotification;
  })
})

const markNotificationAsRead = (notification) => {
  if (notification.status !== 'Unread') return;
  props.markAsRead(notification.name)
}
</script>