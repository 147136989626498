import { createApp, reactive } from "vue";
import App from "./App.vue";
import './style.css';
import "preline/preline";

import router from './router';
import resourceManager from "../../../doppio/libs/resourceManager";
import call from "../../../doppio/libs/controllers/call";
// import socket from "../../../doppio/libs/controllers/socket";
import Auth from "../../../doppio/libs/controllers/auth";
// import Vue from 'vue';
import * as Sentry from "@sentry/vue";``

import {
  Button,
  Card,
  Input,
  setConfig,
  frappeRequest,
  resourcesPlugin,
} from 'frappe-ui'
import PrivacyPolicy from "./views/PrivacyPolicy.vue";



const app = createApp(App);
const auth = reactive(new Auth());

 
setConfig('resourceFetcher', frappeRequest)

// Plugins

app.use(resourceManager);

app.use(resourcesPlugin)
// Global Properties,
// components can inject this
app.provide("$auth", auth);
app.provide("$call", call);
// app.provide("$socket", socket);



// User agreement check
   const userAgreementStatus = reactive({ accepted: false });

	// Route guard
	router.beforeEach(async (to, from, next) => {
		const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	
		if (requiresAuth) {
		if (!auth.isLoggedIn) {
			window.localStorage.setItem('redirect_after_login', window.location.href);
			next({ name: 'Login', query: { route: to.path } });
		} else if (!userAgreementStatus.accepted) {

			console.log("calling the backend api to chech acceptance status")
			const response = await call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.check_user_agreement_acceptance');
			
			if (response.status === 'success' && response.terms_and_privacy_accepted ) {
				console.log ("terms and privacy accepted returned True !! Navigating to the app")

				// fetch the user role and redirect guest facilitator back to observation
				const response = await call('curriculum_planner.curriculum_planner.common.user_details.api.is_guest_facilitator');
				const isGuestFacilitator = response;
				
				if (isGuestFacilitator) {
					if (to.path.startsWith('/front-portal/observations/view')) {
					  next();
					} 
					else if (to.path !== '/front-portal/observations') {
					  next({ path: '/front-portal/observations' });
					} 
					else {
					  next();
					}
				  } else {
					next();
				  }

			 next();
			}
			
			else if (response.status === 'success' && !response.terms_and_privacy_accepted) {
			    // Redirect to login page with a query parameter to show terms
				console.log("user agreement not accepted, redirecting to login from gaurd funtion with query params===>")
				next({ name: 'Login', query: { terms_of_use_accepted: response.terms_of_use_accepted, privacy_policy_accepted:response.privacy_policy_accepted } });
				
			}
			else {
				// Redirect to login page in case of an error
				console.log("user agreement error, redirecting to login without query params")
				next({ name: 'Login'});
			}
		 } 
		else {
			console.log("Next getting executed in Main js ===========================================================>>")
			next();
		}
	   } 
	   else {
		next();
	   }
	});


Sentry.init({
	app,
	dsn: "https://5b1f012dcbdce445eb8b2839308bc678@o4507921916362752.ingest.us.sentry.io/4507921921015808",
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
  
app.use(router);

app.mount("#app");

