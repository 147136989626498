<template>
    <aside ref="sidebar" class="hide-scrollbar bg-customSidebar w-full fixed bottom-0 md:min-h-auto md:static md:w-auto md:flex-col flex flex-row items-center justify-center md:justify-center px-4 md:px-7 md:h-[91.5vh]">
      <div class="flex flex-row items-center gap-3 md:gap-5 md:flex-col md:items-center md:pt-2 md:pb-[] overflow-auto">
        <SidebarButton label="Dashboard" :path="dashboardPath" :iconSelected="DashboardIconSelected" :iconUnselected="DashboardIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
        
        <SidebarButton label="Schedule" path="/front-portal/calendar" :iconSelected="CalendarIconSelected" :iconUnselected="CalendarIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
        
        <SidebarButton v-if="userDetails.is_system_manager || userDetails.user_roles.includes('Educator')" label="Activities" path="/front-portal/activities" :iconSelected="ActivitiesIconSelected" :iconUnselected="ActivitiesIconUnselected"/>
        
        <SidebarButton v-if="userDetails.is_system_manager || userDetails.user_roles.includes('Educator')" label="Educators" path="/front-portal/educators" :iconSelected="EducatorsIconSelected" :iconUnselected="EducatorsIconUnselected"/>
       
        <SidebarButton label="Worksheets" path="/front-portal/worksheets" :iconSelected="worksheets_selected" :iconUnselected="worksheets_unselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
        <SidebarButton v-if="userDetails.user_roles.length === 1 && userDetails.user_roles.includes('Parent')"  label="Quick Learnings" path="/front-portal/educators" :iconSelected="quick_learnings_selected" :iconUnselected="quick_learnings_unselected" />
        <!-- <SidebarButton label="Settings" path="/front-portal/settings" :iconSelected="SettingsIconSelected" :iconUnselected="SettingsIconUnselected" /> -->
  
        <SidebarButton label="Resources" path="/front-portal/resources" :iconSelected="resources_logo" :iconUnselected="resources_logo" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
      </div>
    </aside>
  </template>
  
  <script>
  import SidebarButton from '../SidebarButton.vue';
  
  import DashboardIconSelected from '@/assets/dashboard_selected.png';
  import DashboardIconUnselected from '@/assets/dashboard_unselected.png';
  import CalendarIconSelected from '@/assets/calendar_selected.png';
  import CalendarIconUnselected from '@/assets/calendar_unselected.png';
  import ActivitiesIconSelected from '@/assets/activities_selected.png'; 
  import ActivitiesIconUnselected from '@/assets/activities_unselected.png'; 
  import LearnersIconSelected from '@/assets/learners_selected.png'; 
  import LearnersIconUnselected from '@/assets/learners_unselected.png'; 
  import EducatorsIconSelected from '@/assets/educators_selected.png';
  import EducatorsIconUnselected from '@/assets/educators_unselected.png'; 
  import SettingsIconSelected from '@/assets/settings_selected.png'; 
  import SettingsIconUnselected from '@/assets/settings_unselected.png'; 
  import ObservationsIconSelected from '@/assets/observations_selected.png';
  import ObservationsIconUnselected from '@/assets/observations_unselected.png';
  import quick_learnings_unselected from '@/assets/quick_learnings_unselected.png';
  import quick_learnings_selected from '@/assets/quick_learnings_selected.png';
  import worksheets_selected from '@/assets/worksheets_selected.png';
  import worksheets_unselected from '@/assets/worksheets_unselected.png';
  import resources_logo from '@/assets/resources_logo.png';
                             
  
  export default {
    components: {
      SidebarButton,
    },
    props: {
      userDetails: {
        type: Object,
        required: true,
      },
    },
    // inject: ['$userDetails'],
    mounted(){
        console.log("user details and roles recieved in sidebar",this.userDetails)  
        if (this.userDetails && !this.userDetails.is_system_manager && !this.userDetails.user_roles.includes('Educator')) {
          if (this.userDetails.user_roles.includes('Parent')){
           this.$refs.sidebar.classList.remove('md:justify-center');
           this.$refs.sidebar.classList.add('md:justify-start');
           this.$refs.sidebar.classList.add('md:pt-14');
  
            } 
      }
    },
    data() {
      return {
        DashboardIconSelected,
        DashboardIconUnselected,
        CalendarIconSelected,
        CalendarIconUnselected,
        ActivitiesIconSelected,
        ActivitiesIconUnselected,
        LearnersIconSelected,
        LearnersIconUnselected,
        EducatorsIconSelected,
        EducatorsIconUnselected,
        SettingsIconSelected,
        SettingsIconUnselected,
        ObservationsIconSelected,
        ObservationsIconUnselected,
        worksheets_selected,
        worksheets_unselected,
        quick_learnings_selected,
        quick_learnings_unselected,
        resources_logo
      };
    },
    computed: {
      dashboardPath() {
        if (this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator')) {
          return '/front-portal/educator-dashboard';
        } else if (this.userDetails.user_roles.includes('Parent')) {
          return '/front-portal/parent-dashboard';
        } else {
          return '/';  // Fallback path if no specific role matches
        }
      },
      learnersPath() {
        if (this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator')) {
          return '/front-portal/photo-essays';
        } else if (this.userDetails.user_roles.includes('Parent')) {
          return '/front-portal/learners';
        } else {
          return '/';  // Fallback path if no specific role matches
        }
      },
      isGuestFacilitator() {
        console.log("this is the guest facilitator role",this.userDetails.user_roles)
        return this.userDetails.user_roles.includes('Guest Facilitator');
      }
    },
    methods: {
      handleAlternativeClick() {
        // Your alternative function logic here
        console.log('Alternative click handled for guest facilitator===============================>');
        this.$emit('show-modal'); 
      }
    }
  };
  </script>
  