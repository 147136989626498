<template>
  <div class="fixed bottom-2 right-2 flex flex-col gap-2 z-50">
    <!-- <button @click="addToQueue">Add</button> -->
    <NotificationToast v-for="(notification, index) in props.notificationQueue" 
      v-if="props.notificationQueue" :notification="notification"
      :queueIndex="index" :closeFn="props.makeInactive" />
  </div>
</template>

<script setup>
import NotificationToast from '@/components/notifications/NotificationToast.vue';
import { ref, inject } from 'vue';

const props = defineProps([
  'addToQueueFn', 'makeInactive', 'notificationQueue'
])

const sampleNoti = {
  actor_name: 'Patol Babu',
  type: 'Observation Comment',
  status: 'Unread'
}

const addToQueue = () => {
  props.addToQueueFn(sampleNoti)
}

const userDetails = inject('userDetails')
</script>