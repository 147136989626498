<template>
  <div :class="cn(`border border-gray-200 p-3 rounded-md flex gap-2`,
    props.notification.status === 'Unread' && 'bg-[#FDF9EC] cursor-pointer'
  )">
    <p v-if="props.notification.actor_name" class="size-8 bg-[#FF8B70] text-white 
      flex items-center justify-center rounded-full shrink-0">
      <!-- Replace with user avator later-->
      {{ props.notification.actor_name[0] }}
    </p>
    <div class="flex flex-col gap-2 flex-auto">
      <p class="text-xs font-medium">
        <span class="font-semibold">{{ props.notification.actor_name || 'someone' }} &nbsp;</span>
        <span>{{ description }}</span>
      </p>
      <div>
        <router-link :to="`/front-portal/observations/view/${getObservType(props.notification.source_type)}/all?observ=${props.notification.source_id}&scroll=1`"
          v-if="(props.notification.type === 'Observation Comment' || props.notification.type === 'Observation Comment Tag')
            && !!props.notification.source_id"
          >
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium
            rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 focus:outline-none
            focus:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:bg-white
            dark:text-neutral-800">
            View
          </button>
        </router-link>
      </div>
      <div class="text-gray-600 text-xs text-right">
        {{ formatDateWithSuffix(props.notification.creation_time) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js';
import { formatDateWithSuffix } from '@/lib/utils.js';
import { computed } from 'vue';
import { HSDropdown } from 'preline/preline'

const props = defineProps([
  'notification'
])

// console.log(props.notification)

const description = computed(() => {
  if (!props.notification) return ''
  if (props.notification.type === 'Observation Comment')
    return `commented on an observation you posted.`
  if (props.notification.type === 'Observation Comment Tag')
    return `tagged you in a comment.`
  return 'Random Test Notification'
})

const getObservType = (doctype) => {
  const baseDoctype = doctype.split(' ').slice(0, 2).join(' ')
  if (baseDoctype === 'Daily Observations') return 'child'
  if (baseDoctype === 'Activity Observations') return 'activity'
  if (baseDoctype === 'Unstructured Feedback') return 'unstructured'
}

// const closeDropdown = () => {
//   console.log("closing dropdown")
//   // const dropdown = new HSDropdown(document.querySelector('#notificationDropdown'));
//   const { dropdown } = HSDropdown.getInstance('#notificationDropdown', true);
//   console.log(dropdown)
//   if (dropdown)
//     dropdown.close(true)
//   // HSDropdown.close('#notificationDropdown')
// }
</script>